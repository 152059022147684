<template>
  <app-page container>
    <template v-slot:app-bar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{
        $vuetify.lang.t("$vuetify.make_a_donation")
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </template>
    <form @submit.prevent="makeDonation">
        <p>
          {{this.$route.query.id 
              ? `${$vuetify.lang.t('$vuetify.with_this_donation')}, ${this.$store.state.pet.activePetDetail.name} ${$vuetify.lang.t('$vuetify.recieve_the_care')}`
              : $vuetify.lang.t('$vuetify.support_mpawer')
          }}
        </p>

        <v-text-field
            v-model="amount"
            outlined
            hide-details
            class="rounded-md mb-3"
            type="number"
            :label="$vuetify.lang.t('$vuetify.make_donation_placeholder_input')"
        ></v-text-field>
        <v-btn
            type="submit"
            color="primary"
            class="mr-5"
          >
         {{ $vuetify.lang.t('$vuetify.your_donation') }}
        </v-btn>
    </form>
  </app-page>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const userModule = createNamespacedHelpers("user");
import { BASE_URL } from '@/config'
export default {
  name: 'Donation',
  data () {
    return{
      amount: '',
    };
  },
  computed: {
    ...userModule.mapState(["user"]),
  },
  methods: {
    ...userModule.mapActions(["MAKE_A_DONATION"]),

    makeDonation () {
      if (this.amount === '' || this.amount === '0' || this.amount === 0) {
        return;
      }
        
      this.amount = +this.amount;
      this.amount = this.amount.toFixed(2);
      
      let payload = {
        amount: this.amount,
        success_url: `${BASE_URL}${this.$route.path}`,
        description: `${this.user.name} donates an amount of ${this.amount} euros`
      };

      if (Object.keys(this.$route.query).length > 0) {
        payload['description'] = `${this.user.name} donates an amount of ${this.amount} euros to the ${this.$route.query?.type}${this.$route.query?.id}`;

        payload['success_url'] += '?'
        Object.keys(this.$route.query).forEach((item, index) => {
          payload['success_url'] += `${item}=${this.$route.query[item]}${index<Object.keys(this.$route.query).length-1 ? '&' : ''}`
        })
      }

      this.MAKE_A_DONATION(payload).then((response) => {
        this.amount = '';
        window.open(response.data.data.redirect_url, "_self", "location=yes");
      }).catch(err => console.error(err));
    },
  },
}
</script>

<style>

</style>